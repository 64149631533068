<template>
  <div>
    <HeaderBanner
      :title="headerText.title"
      :small="headerText.small"
      :img="headerText.img"
    ></HeaderBanner>
    <div class="titleBox">
      <h2>多平台发布、多账号管理</h2>
      <p>图文、视频发布参数一键配置，20+自媒体平台多个账号一键发布</p>
    </div>
    <ul class="logos flex between widthCenter">
      <li><img src="../assets/imgs/media/logo/01.png" alt=""></li>
      <li><img src="../assets/imgs/media/logo/02.png" alt=""></li>
      <li><img src="../assets/imgs/media/logo/03.png" alt=""></li>
      <li><img src="../assets/imgs/media/logo/04.png" alt=""></li>
      <li><img src="../assets/imgs/media/logo/05.png" alt=""></li>
      <li><img src="../assets/imgs/media/logo/06.png" alt=""></li>
      <li><img src="../assets/imgs/media/logo/07.png" alt=""></li>
      <li><img src="../assets/imgs/media/logo/08.png" alt=""></li>
      <li><img src="../assets/imgs/media/logo/09.png" alt=""></li>
      <li><img src="../assets/imgs/media/logo/10.png" alt=""></li>
      <li><img src="../assets/imgs/media/logo/11.png" alt=""></li>
      <li><img src="../assets/imgs/media/logo/12.png" alt=""></li>
      <li><img src="../assets/imgs/media/logo/13.png" alt=""></li>
      <li><img src="../assets/imgs/media/logo/14.png" alt=""></li>
      <li><img src="../assets/imgs/media/logo/15.png" alt=""></li>
      <li><img src="../assets/imgs/media/logo/16.png" alt=""></li>
      <li><img src="../assets/imgs/media/logo/17.png" alt=""></li>
      <li><img src="../assets/imgs/media/logo/18.png" alt=""></li>
      <li><img src="../assets/imgs/media/logo/19.png" alt=""></li>
      <li>持续更新中...</li>
    </ul>
    <LeftImg
      :title="div1.title"
      :small="div1.small"
      :text1="div1.text1"
      :text2="div1.text2"
      :text3="div1.text3"
      :img="div1.img"
    ></LeftImg>
    <RightImg
      :title="div2.title"
      :small="div2.small"
      :text1="div2.text1"
      :text2="div2.text2"
      :text3="div2.text3"
      :img="div2.img"
    ></RightImg>
    <FormEnd></FormEnd>
  </div>
</template>

<script>
import HeaderBanner from "../components/headerBanner.vue";
import LeftImg from "../components/leftImg.vue";
import RightImg from "../components/rightImg.vue";
export default {
  name: "media",
  components: { HeaderBanner, LeftImg, RightImg },
  data() {
    return {
      headerText: {
        title: "创变新媒体营销解决方案",
        small: "AI助力内容生产，多平台账号一键发布，让新媒体运营更轻松",
        img: require("../assets/imgs/media/banner.png"),
      },
      div1: {
        title: "AI一键生成文章+图片",
        small: "输入关键字、主题、行业和风格，AI一键生成文章和图片，从而帮助内容创作者在写作过程中保持创意与灵感，快速批量生成内容...",
        img: require("../assets/imgs/media/AI.png"),
      },
      div2: {
        title: "多维度数据分析，驱动效果优化",
        text1: "打通多平台数据孤岛，全链路监测数据，提供全场景、多维度数据分析管理能力；",
        text2: "可视化数据看板，让内容营销数据效果一目了然...",
        img: require("../assets/imgs/media/optimize.png"),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.titleBox {
  text-align: center;
  margin: 100px 0 50px 0;
  h2 {
    font-size: 40px;
    margin-bottom: 10px;
  }
  p{
    color: #666;
    font-weight: 600;
    font-size: 16px;
  }
}
.logos{

  li{
    width: 10%;
    margin-bottom: 20px;
    text-align: center;
  }
  img{
    width: 60%;
  }
  flex-wrap: wrap;
}
</style>